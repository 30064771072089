// Routes

// Not found
export const routePath404 = '/404';

// Login
export const routePathLogin = '/login';

// Sign Up
export const routePathSignUp = '/signup';
export const routePathSelectProfileType = `${routePathSignUp}/select-profile-type`;
export const routePathSignUpStep1 = `${routePathSignUp}/step-1`;
export const routePathSignUpStep2 = `${routePathSignUp}/step-2`;
export const routePathSignUpStep3 = `${routePathSignUp}/step-3`;
export const routePathWelcomeAfterSignUp = `${routePathSignUp}/welcome`;
export const routePathWelcomeAfterQuickSignUp = `${routePathWelcomeAfterSignUp}?quickSignup=true`;

// Sign Up Pro
export const routePathSignUpProStep1 = '/signup/pro/step-1';
export const routePathSignUpProStep2 = '/signup/pro/step-2';
export const routePathSignUpProStep3 = '/signup/pro/step-3';
export const routePathSignUpProStep4 = '/signup/pro/step-4';
export const routePathSignUpProCrmSync = '/signup/pro/crm-sync';
export const routePathSignUpProCreateCompanyStep = '/signup/pro/create-company-step-';
export const routePathSignUpProCreateCompanyStep1 = '/signup/pro/create-company-step-1';
export const routePathSignUpProCreateCompanyStep2 = '/signup/pro/create-company-step-2';
export const routePathWelcomeAfterSignUpPro = '/signup/pro/welcome';
export const routePathWelcomeAfterQuickSignUpPro = '/signup/pro/welcome?quickSignup=true';

// Profile
export const routePathProfile = '/profile';
export const routePathProfileNetwork = `${routePathProfile}/network`;
export const routePathProfilePosts = `${routePathProfile}/posts`;
export const routePathProfileProjects = `${routePathProfile}/projects`;
export const routePathProfileInterests = `${routePathProfile}/interests`;
export const routePathProfileRatings = `${routePathProfile}/ratings`;
export const routePathProfileStatistics = `${routePathProfile}/statistics`;
export const routePathProfileSimulations = `${routePathProfile}/simulations`;
export const routePathProfileCrmSync = `${routePathProfile}/crm-sync`;
export const routePathProfileSecurity = `${routePathProfile}/security`;
export const routePathProfileHelp = `${routePathProfile}/help`;
export const routePathProfileLegal = `${routePathProfile}/legal`;
export const routePathProfileCompany = `${routePathProfile}/company`;
export const routePathProfileCompanyShowcase = `${routePathProfileCompany}/showcase`;
export const routePathProfileCompanyJobs = `${routePathProfileCompany}/jobs`;
export const routePathProfileCompanyJobsCreateJobOffer = `${routePathProfileCompanyJobs}/create-job-offer`;
export const routePathProfileCompanyInfo = `${routePathProfileCompany}/info`;
export const routePathProfileCompanyInfoCrmSync = `${routePathProfileCompanyInfo}/crm-sync`;
export const routePathProfileCompanyMembers = `${routePathProfileCompany}/members`;
export const routePathProfileCompanyActivity = `${routePathProfileCompany}/activity`;
export const routePathProfileCompanyFavorites = `${routePathProfileCompany}/favorites`;
export const routePathProfileCompanyCreateCompanyStep = `${routePathProfileCompany}/create-company-step-`;
export const routePathProfileCompanyCreateCompanyStep1 = `${routePathProfileCompany}/create-company-step-1`;
export const routePathProfileCompanyCreateCompanyStep2 = `${routePathProfileCompany}/create-company-step-2`;
export const routePathProfileCompanyCrmSync = `${routePathProfileCompany}/crm-sync`;
export const routePathProfileAds = `${routePathProfile}/ads`;
export const routePathProfileEstimate = `${routePathProfile}/estimate`;
export const routePathProfileEstimateStep1 = `${routePathProfileEstimate}/step-1`;
export const routePathProfileEstimateFindAPro = `${routePathProfileEstimate}/find-a-pro`;
export const routePathProfileEstimateResults = `${routePathProfileEstimate}/results`;
export const routePathProfileCompanyReceivedPendingRequest = `${routePathProfileCompanyMembers}/received-pending-requests`;
export const routePathProfileCompanySentPendingRequest = `${routePathProfileCompanyMembers}/sent-pending-requests`;
export const routePathProfileCompanyAdminRights = `${routePathProfileCompanyMembers}/admin-rights`;
export const routePathProfileCompanyTeam = `${routePathProfileCompanyMembers}/team`;
export const routePathProfileCompanyMemberCompanies = `${routePathProfileCompanyMembers}/member-companies`;

// Create Ad
export const routePathCreateAdStep = '/ad/create/step-';
export const routePathCreateAdStep1 = `${routePathCreateAdStep}1`;
export const routePathCreateAdStep2 = `${routePathCreateAdStep}2`;
export const routePathCreateAdStep8 = `${routePathCreateAdStep}8`;
export const routePathCreateAdConfirmation = `/ad/create/confirmation`;

// Community
export const routePathCommunity = '/community';
export const routePathCommunityCategory = `${routePathCommunity}/category`;
export const routePathCommunitySearch = `${routePathCommunity}/search`;

// Notifications
export const routePathNotifications = '/notifications';
export const routePathNotificationsInvitations = `${routePathNotifications}/invitations`;
export const routePathNotificationsCollaboratorInvitations = `${routePathNotifications}/collaborator-invitations`;

// Estimate
export const routePathEstimation = '/estimate';
export const routePathEstimationStep = `${routePathEstimation}/step-`;

// Other
export const routePathHome = '/';
export const routePathAd = '/ad';
export const routePathNews = '/news';
export const routePathNewsPost = '/news-post';
export const routePathContacts = '/contacts';
export const routePathFavorites = '/galleries';
export const routePathInterests = '/signup/interests';
export const routePathUser = '/user';
export const routePathCompany = '/company';
export const routePathAgencies = '/agencies';
export const routePathCompanyPagesJaunes = '/company-pagesjaunes';
export const routePathForgotPassword = '/forgot-password';

// With Query Params
export const routePathCreateAdStep1WithTypeSell = `${routePathCreateAdStep1}?type=sell`;
export const routePathCreateAdStep1WithTypeRent = `${routePathCreateAdStep1}?type=rent`;
export const routePathCreateAdStep1WithEstimationId = `${routePathCreateAdStep1}?estimationId=`;
export const routePathCreateAdStep1WithAdId = `${routePathCreateAdStep1}?adId=`;
export const routePathNewsWithShowTooltip = `${routePathNews}?showTooltip=true`;
export const routePathProfileCompanyActivityWithTabSelect = `${routePathProfileCompanyActivity}?tabSelect=`;
export const routePathNewsWithPostId = `${routePathNews}?postId=`;
export const routePathCreateAdConfirmationWithId = `${routePathCreateAdConfirmation}?confirmationAdId=`;
export const routePathProfileNetworkWithSeeAllWoopenContacts = `${routePathProfileNetwork}?seeAll=WoopenContacts`;
export const routePathProfileNetworkWithSeeAllNonWoopenContacts = `${routePathProfileNetwork}?seeAll=NonWoopenContacts`;
export const routePathContactsWithUserId = `${routePathContacts}?userId=`;
export const routePathContactsWithChannelUrl = `${routePathContacts}?channelUrl=`;
export const forgotPasswordWithEmail = `${routePathForgotPassword}?email=`;
export const routePathCreateCompanyFromPagesJaunesClaim = `${routePathProfileCompanyCreateCompanyStep1}?companyPagesJaunesId=`;
export const routePathAdWithSearchId = `${routePathAd}?searchId=`;
export const routePathAdWithSavedSearchListModalOpen = `${routePathAd}?savedSearchListModalOpen=true`;
export const routePathEstimationStep2WithPropertyType = `${routePathEstimationStep}2?propertyType=`;
