import React from 'react';

import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import Typography from '@mui/material/Typography';

import Box from '@mui/material/Box';

import { useTranslation } from 'next-i18next';

import Divider from 'src/components/ui/Divider';
import TextField from 'src/components/ui/TextField';
import PasswordInput from 'src/components/ui/PasswordInput';
import Checkbox from 'src/components/ui/Checkbox';
import useAuthActions from 'src/hooks/useAuthActions';
import useAppRouter from 'src/hooks/useAppRouter';
import useLoginModal from 'src/hooks/useLoginModal';
import Button from 'src/components/ui/Button';
import BlueLink from 'src/components/ui/BlueLink';
import ArrowLink from 'src/components/ui/ArrowLink';
import { LoginButtonsContainer, LoginButton } from 'src/components/auth/SocialSignInButtons';
import { colours } from 'src/constants/colours';
import { breakpoints } from 'src/constants/breakpoints';
import { routePathSelectProfileType, forgotPasswordWithEmail } from 'src/constants/router';
import { styledComponent } from 'src/utils/styled';

type FormValues = {
    email: string;
    password: string;
    rememberMe: boolean;
};

const StyledDivider = styledComponent(Divider)`
    margin-right: 9rem;
    margin-left: 9rem;
`;

const StyledBox = styledComponent(Box)`
    text-align: center;
    color: ${colours.linkGrey};
`;

const StyledTypography = styledComponent(Typography)`
    @media (max-width: ${breakpoints.breakPoint450 - 1}px) {
        font-size: 0.7rem;
    }
`;

const StyledArrowLink = styledComponent(ArrowLink)`
    @media (max-width: ${breakpoints.breakPoint450 - 1}px) {
        font-size: 0.7rem;
    }
`;

interface Props {
    title?: string;
    isEmailVerify?: boolean;
    returnUrl?: string;
}

const LoginForm: React.FC<Props> = ({ title, isEmailVerify = false, returnUrl }) => {
    const { signIn, signUpSocial } = useAuthActions();
    const { closeLoginModal } = useLoginModal();
    const router = useAppRouter();
    const { t } = useTranslation();

    const yupSchema = Yup.object().shape({
        email: Yup.string().email(t('common:errors.invalidEmail')).required(t('common:errors.required')),
        password: Yup.string()
            .required(t('common:errors.required'))
            .min(8, t('common:errors.tooShort', { number: 8 })),
        rememberMe: Yup.boolean().required(t('errors.required')),
    });

    const handleForm = async (values: FormValues) => {
        await signIn({
            email: values.email,
            password: values.password,
            rememberMe: values.rememberMe,
            returnUrl,
        });
        closeLoginModal();
    };

    return (
        <Formik
            initialValues={{
                email: '',
                password: '',
                rememberMe: false,
            }}
            validationSchema={yupSchema}
            onSubmit={handleForm}
        >
            {({ values }) => (
                <Form>
                    <Box>
                        {isEmailVerify ? (
                            <Box>
                                <StyledBox>
                                    <Typography variant="h3">{t('common:emailVerifyPage.thanks')}</Typography>
                                </StyledBox>

                                <Box mb={5} textAlign="center" color={colours.blackOpacity.O60}>
                                    <Typography>{t('common:emailVerifyPage.checkMessage')}</Typography>
                                </Box>

                                <Box mt={4} mb={3}>
                                    <StyledDivider />
                                </Box>
                                <Box ml={5} mt={5} mb={5}>
                                    <Typography variant="h2">{t('common:emailVerifyPage.connection')}</Typography>
                                </Box>
                            </Box>
                        ) : (
                            <>
                                <Typography variant="h2" data-target="login-title" data-target-id="login-title">
                                    {title ?? t('common:signIn.title')}
                                </Typography>
                                <Box display="flex" alignItems="center" mt={2} mb={4}>
                                    <Box mr={1}>
                                        <StyledTypography>{t('common:signIn.signUpQuestion')}</StyledTypography>
                                    </Box>
                                    <StyledArrowLink
                                        onClick={async (ev) => {
                                            ev.preventDefault();
                                            ev.stopPropagation();
                                            await router.push(routePathSelectProfileType);
                                        }}
                                    >
                                        {t('common:signIn.signUpLink')}
                                    </StyledArrowLink>
                                </Box>
                            </>
                        )}
                    </Box>

                    <LoginButtonsContainer>
                        <LoginButton
                            provider="google"
                            onClick={() => signUpSocial({ provider: 'google', returnUrlAfterLogin: returnUrl })}
                        />
                        <LoginButton
                            provider="facebook"
                            onClick={() => signUpSocial({ provider: 'facebook', returnUrlAfterLogin: returnUrl })}
                        />
                        <LoginButton
                            provider="apple"
                            onClick={() => signUpSocial({ provider: 'apple', returnUrlAfterLogin: returnUrl })}
                        />
                    </LoginButtonsContainer>

                    <Box mt={4} mb={3}>
                        <Divider label={t('signIn.or')} />
                    </Box>

                    <TextField
                        name="email"
                        label={t('common:signIn.email')}
                        type="email"
                        data-target="login-email"
                        data-target-id="login-email"
                    />

                    <PasswordInput
                        name="password"
                        label={t('common:signIn.password')}
                        data-target="login-password"
                        data-target-id="login-password"
                    />
                    <p>
                        <BlueLink
                            data-target="login-forgot-password-link"
                            data-target-id="login-forgot-password-link"
                            href={`${forgotPasswordWithEmail}${encodeURIComponent(values.email)}`}
                        >
                            {t('common:signIn.forgotPassword')}
                        </BlueLink>
                    </p>
                    <Box mb={4}>
                        <Checkbox
                            name="rememberMe"
                            label={t('common:signIn.rememberMe')}
                            data-target="login-checkbox"
                            data-target-id="login-checkbox"
                        />
                    </Box>
                    <Button type="submit" data-target="login-button" data-target-id="login-button">
                        {t('common:signIn.signIn')}
                    </Button>
                </Form>
            )}
        </Formik>
    );
};

export default LoginForm;
