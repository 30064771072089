import type { NextPageContext } from 'next';
import { parseCookies, setCookie, destroyCookie } from 'nookies';

import type { AuthData } from 'src/contexts/AuthProvider';

const cookieKey = 'auth';

export const getAuthCookie = (ctx?: Pick<NextPageContext, 'req' | 'res'>): AuthData | undefined => {
    const { auth } = parseCookies(ctx);
    if (!auth) {
        return undefined;
    }
    return JSON.parse(auth) as AuthData;
};

export const removeAuthCookie = (ctx?: Pick<NextPageContext, 'req' | 'res'>) =>
    destroyCookie(ctx, cookieKey, {
        path: '/',
    });

export const setAuthCookie = (auth: AuthData, expirationDate?: Date) => {
    setCookie(null, cookieKey, JSON.stringify(auth), {
        sameSite: 'strict',
        path: '/',
        expires: expirationDate,
    });
};
